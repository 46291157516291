import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`  
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Nebula Core Technologies - Building the technologies of tomorrow!"
           description="We're a team of international entrepreneurs  and engineers building the technologies of tomorrow."
      /> 
      <main className="header bg-gray-900 relative h-screen grid place-content-center">
      <div className="grid grid-cols-1 md:grid-cols-2 px-10 lx:gird-cols-2 gap-2 lg:px-20"> 
          <div className="m-auto pb-10 lg:pt-40">
            <h1 className="font-semibold text-4xl text-green-300">
              Nebula Core Technologies
            </h1>
            <p className="mt-4 text-lg leading-relaxed text-yellow-100">
              We're a team of international entrepreneurs  and engineers building the technologies of tomorrow.
            </p> 
          </div> 
          <div className="m-auto">
            <img
              alt="..."
              className="rounded-lg bg-cover bg-center"
              src="https://cdn.dribbble.com/users/1531086/screenshots/3526169/rocket_animation_08.gif"
            />
          </div>
        </div> 
      </main> 
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
